import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const HeroImage = function () {
  return (
    <div>
      {/* Hero card */}

      <div className="relative">
        <div className="w-100">
          <div className="relative py-52 sm:overflow-hidden md:h-screen ">
            <div className="absolute inset-0">
              <StaticImage
                // src="../images/sugarr-natural-hair-removal.jpg"
                src="../images/sugarr-interior.jpg"
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Natural Hair Removal"
                className="h-full w-full object-cover"
                layout="constrained"
              />
              <div className="absolute inset-0 bg-black opacity-30 mix-blend-multiply" />
            </div>

            <div className=" relative flex h-3/4 flex-col items-center justify-center gap-y-3 px-5 align-middle">
              <p className="mx-auto max-w-lg text-center text-xl font-light text-gray-100 sm:max-w-3xl md:text-3xl">
                Body Sugaring in St. Thomas, ON
              </p>
              <h1 className="text-center text-4xl font-normal tracking-tight text-white sm:text-5xl md:w-11/12  md:text-7xl lg:w-3/4 lg:text-7xl xl:w-3/5">
                <span className="">Sugaring Hair Removal</span>
              </h1>
              <p className="mx-auto max-w-lg text-center text-xl font-light text-gray-100 sm:max-w-3xl md:text-3xl">
                Look Good, Feel Good.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
